<template>
    <section class="intima-faq py-5 overflow-hidden">
        <div class="container">
            <h1 class="display-3 d-none d-md-block text-center font-weight-bold mb-4"
                data-aos="fade-right"
                data-aos-duration="1000">
                FAQ
            </h1>
            <h1 class="display-4 d-block d-md-none text-center font-weight-bold mb-4"
                data-aos="fade-right"
                data-aos-duration="1000">
                FAQ
            </h1>

            <div class="row mb-3">
                <div class="col-md-4 pr-md-2 pr-lg-3">
                    <div class="row no-gutters">
                        <div class="col-6 col-md-12 pr-2 pr-md-0">
                            <button class="btn btn-lg btn-block btn-emsculpt font-weight-bold text-white text-nowrap border-lg rounded-lg px-0 mb-2"
                                    v-bind:class="{ 'active': tabActiveIndex === 0 }"
                                    v-on:click="tabActiveIndex = 0">
                                療程對象
                            </button>
                        </div>
                        <div class="col-6 col-md-12 pl-2 pl-md-0">
                            <button class="btn btn-lg btn-block btn-emsculpt font-weight-bold text-white text-nowrap border-lg rounded-lg mb-2"
                                    v-bind:class="{ 'active': tabActiveIndex === 1 }"
                                    v-on:click="tabActiveIndex = 1">
                                療程感受
                            </button>
                        </div>
                        <div class="col-6 col-md-12 pr-2 pr-md-0">
                            <button class="btn btn-lg btn-block btn-emsculpt font-weight-bold text-white text-nowrap border-lg rounded-lg mb-2"
                                    v-bind:class="{ 'active': tabActiveIndex === 2 }"
                                    v-on:click="tabActiveIndex = 2">
                                療程效果
                            </button>
                        </div>
                        <div class="col-6 col-md-12 pl-2 pl-md-0">
                            <button class="btn btn-lg btn-block btn-emsculpt font-weight-bold text-white text-nowrap border-lg rounded-lg px-0 mb-3 mb-md-0"
                                    v-bind:class="{ 'active': tabActiveIndex === 3 }"
                                    v-on:click="tabActiveIndex = 3">
                                安全性及復原期
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="border-lg rounded-lg d-flex align-items-center h-100 p-4">
                        <div v-if="tabActiveIndex === 0">
                            <h4 class="font-weight-bold">
                                收陰機療程適合甚麼人士？
                            </h4>
                            <h5 class="font-weight-normal mb-3">
                                適合任何希望提高性質量的成年女性，對於曾經生育及年紀稍長女性，還可以解決因盆底肌鬆弛引起的尿滲問題，減低子宮下垂風險。
                            </h5>
                        </div>
                        <div v-else-if="tabActiveIndex === 1">
                            <h4 class="font-weight-bold">
                                收陰機療程時感覺如何？會痛嗎？
                            </h4>
                            <h5 class="font-weight-normal mb-3">
                                治療過程中只有輕微的溫熱感，並感覺私密部位肌肉收縮，並無痛楚不適。
                            </h5>
                        </div>
                        <div v-else-if="tabActiveIndex === 2">
                            <h4 class="font-weight-bold">
                                收陰機效果能維持多久？
                            </h4>
                            <h5 class="font-weight-normal mb-3">
                                首次療程後，即時感到陰道內壁收緊，外觀亦有改善。於數週內，緊緻效果會逐漸顯著。完成5次療程，緊緻效果基本上可維持1-2年或以上。
                            </h5>
                        </div>
                        <div v-else>
                            <h4 class="font-weight-bold">
                                收陰機療程安全嗎？
                            </h4>
                            <h5 class="font-weight-normal mb-3">
                                此療程是一項非入侵性治療，不會造成傷口。經FDA美國食品及藥物管理局批核，及獲得歐盟CE認證，符合標準，安全可靠。
                            </h5>

                            <h4 class="font-weight-bold">
                                收陰機療程需要復原期嗎？
                            </h4>
                            <h5 class="font-weight-normal">
                                療程後可如常活動或進行性行為，並無復原期。
                            </h5>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <p>
                    *療程效果因人而異<br>
                    *資料及數據由 BTL HONG KONG LIMITED 提供<br>
                    療程只限年滿18歲或以上之客人<br>
                    <!--詳情請參閱：
                    <a href="https://www.btlmedical.com/tc/customer-btl-emsculpt"
                       target="_blank"
                       rel="noreferrer nofollow noopener">
                        https://www.btlmedical.com/tc/customer-btl-emsculpt
                    </a> -->
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "IntimaFaq",
        data () {
            return {
                tabActiveIndex: 0,
            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .intima-faq {
        background-image: url($background);
        background-color: $background-color;
        color: $text-color;

        .border-lg {
            border: solid $text-color 3px;
        }

        .rounded-lg {
            border-radius: 15px !important;
        }

        .btn-emsculpt {
            &.active,
            &:hover,
            &:focus,
            &:active {
                background-color: rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(2px);
            }
        }
    }
</style>
